import React, { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { IS_DEV } from "@/__main__/constants.mjs";
import {
  loadSeenGamePromoFeatures,
  markGamePromoFeaturesAsSeen,
} from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import {
  EVENT_PROMO_MODAL_CLOSE,
  GAME_BOX_ICONS,
  GAME_COLORS,
  GAME_IMG_BANNERS,
  GAME_NAME_MAP,
} from "@/app/constants.mjs";
import { getGameDefinition } from "@/app/games.mjs";
import type { FullModalRefOptions } from "@/shared/FullModal.jsx";
import FullModal from "@/shared/FullModal.jsx";
import { useCurrentGame } from "@/util/game-route.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MAX_FEATURE_AGE_DAYS = -7; // dont show if older than this
const MAX_FEATURE_COUNT = 3; // show at most this many features

function GamePromoFeatures() {
  const { t } = useTranslation();
  const modalRef = useRef<FullModalRefOptions>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    marketing: { seenPromoFeatures },
    volatile: { isCMPBannerVisible },
  } = useSnapshot(readState);

  useEffect(() => {
    async function doLoad() {
      await loadSeenGamePromoFeatures();
      setIsLoaded(true);
    }

    doLoad();
  }, []);

  const gameSymbol = useCurrentGame();
  const gamePromoFeatures = getGameDefinition(gameSymbol)?.promoFeatures || [];
  const renderedFeatures = gamePromoFeatures
    .filter((feature) => {
      if (seenPromoFeatures[feature.key]) return false;
      const currentDate = new Date();
      const differenceMs = feature.date.getTime() - currentDate.getTime();
      const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
      if (differenceDays > 0) return false;
      const tooOld = differenceDays <= MAX_FEATURE_AGE_DAYS;
      return !tooOld;
    })
    .slice(0, MAX_FEATURE_COUNT);

  function handleModalClose() {
    markGamePromoFeaturesAsSeen(
      gamePromoFeatures
        .filter((feature) => feature.key)
        .map((feature) => feature.key),
    );
  }

  useEffect(() => {
    // This is just so we can actually view these promos in dev
    if (IS_DEV && isLoaded && gameSymbol && renderedFeatures.length) {
      modalRef.current?.showModal();
      return;
    }

    if (
      !modalRef?.current ||
      isCMPBannerVisible === undefined ||
      isCMPBannerVisible
    )
      return;

    if (isLoaded && gameSymbol && renderedFeatures.length) {
      modalRef.current?.showModal();
    } else {
      modalRef.current?.close();
    }
  }, [isLoaded, gameSymbol, renderedFeatures, isCMPBannerVisible, modalRef]);

  const GameColor = GAME_COLORS[gameSymbol];
  const GameIcon = GAME_BOX_ICONS[gameSymbol];
  const GameName = GAME_NAME_MAP[gameSymbol];
  const GameImgHero = GAME_IMG_BANNERS[gameSymbol];

  return (
    <FullModal
      ref={modalRef}
      onModalClose={() => {
        handleModalClose();
        eventBus.emit(EVENT_PROMO_MODAL_CLOSE, {
          key: "close",
          gameName: GameName,
        });
      }}
    >
      <div className={Container()} style={{ "--game-color": GameColor }}>
        {GameName && (
          <>
            <img src={GameImgHero} className="bg" />
            <header>
              <div className="icon">
                <GameIcon />
              </div>
              <h3>
                {t(
                  "common:hotNewGameFeatures",
                  "Hot new {{gameName}} features",
                  {
                    gameName: t(...GameName),
                  },
                )}
              </h3>
            </header>
            <hr />
          </>
        )}
        <ol>
          {renderedFeatures.map((feature) => (
            <li key={feature.key} data-has-link={!!feature.link}>
              <div className="meta">
                <div className="image-container">
                  <img
                    src={feature.image}
                    alt={t(...feature.title)}
                    width="106"
                    height="103"
                  />
                </div>
                <div className="info">
                  <h4 className="title type-h6">{t(...feature.title)}</h4>
                  <p className="description type-subtitle2">
                    {t(...feature.description)}
                  </p>
                </div>
              </div>
              {feature.link && (
                <Button
                  href={feature.link}
                  onClick={() => {
                    handleModalClose();
                    eventBus.emit(EVENT_PROMO_MODAL_CLOSE, {
                      key: "link_click",
                      gameName: GameName,
                      link: feature.link,
                    });
                  }}
                  emphasis="high"
                >
                  {feature.linkText
                    ? t(...feature.linkText)
                    : t("common:checkItOut", "Check it out!")}
                </Button>
              )}
            </li>
          ))}
        </ol>
      </div>
    </FullModal>
  );
}

export default memo(GamePromoFeatures);

const Container = () => css`
  position: relative;
  min-width: 42rem;
  padding: var(--sp-6);
  background: var(--shade8);
  border-radius: var(--br-xl);
  box-shadow: inset 0 0 0 1px var(--shade3-25);
  overflow: hidden;

  > * {
    position: relative;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 50%;
    translate: -50%;
    width: 225%;
    -webkit-mask-image: linear-gradient(
      to bottom,
      hsl(0deg 0% 0% / 25%),
      transparent
    );
  }

  header {
    display: flex;
    align-items: center;
    gap: var(--sp-3);

    .icon,
    svg {
      height: auto;
      aspect-ratio: 1;
    }
    .icon {
      position: relative;
      width: var(--sp-6);
    }
    svg {
      width: 100%;
      background: var(--game-color);
      color: var(--shade10);
      border-radius: var(--br);
    }
  }

  hr {
    border: none;
    height: 1px;
    background: var(--shade1-15);
    margin-block: var(--sp-3);
  }

  ol {
    display: grid;
    gap: var(--sp-4);
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--sp-8);

    &[data-has-link="false"] {
      .title,
      .description {
        max-width: 48ch;
      }
    }

    .image-container {
      position: relative;
      border: 2px solid var(--shade8);
      box-shadow: 0 0 0 2px var(--shade6);
      border-radius: var(--br-lg);
    }
    img {
      width: 10rem;
      height: auto;
      border-radius: var(--br);
    }

    .meta {
      display: flex;
      align-items: center;
      gap: var(--sp-4);
    }

    .title,
    .description {
      max-width: 32ch;
    }
    .description {
      color: var(--shade1);
    }
  }
`;
